<template>
  <form v-on:submit.prevent="handleSubmit(values)" :class="`form-main flex ${inline ? '' : 'flex-wrap'} justify-center`">
    <div :class="`${inline ? 'flex-grow' : 'w-full'} flex flex-wrap`">
      <div v-for="f in fields" :key="f.index" :class="`relative flex flex-wrap items-end w-full ${fieldWidth(f)} lg:pt-3 px-2 ${inline ? '' : 'mb-2'} ${initialized[f.fields.name] == true ? 'initialized' : 'untouched'} ${focus == f.fields.name ? 'focused' : ''} ${errors && errors.indexOf(f.fields.name) > -1 ? 'error form-error' : ''} ${fullWidthCounterClasses(f)}`" :data-error="errors && errors.indexOf(f.fields.name) > -1 && f.fields.errorMsg ? f.fields.errorMsg : null">
        <div v-if="f.fields.fullWidthCounter && f.fields.type == 'counter'" class="full-width-counter-content">
          <label :for="f.name" v-if="f.fields.type != 'space' && f.fields.type != 'group'" v-html="f.fields.label" :class="`block w-full font-bold uppercase text-tb-purple text-xl label-${f.fields.type} ${labels == true ? '' : 'hideme'}`" />
          <p v-if="f.fields.description" class="mt-1 text-xs uppercase text-purple">
            {{ f.fields.description }}
          </p>
        </div>
        <template v-else>
          <label :for="f.name" v-if="f.fields.type != 'space' && f.fields.type != 'group'" v-html="f.fields.label" :class="`block w-full ${f.fields.type == 'counter' ? 'font-bold uppercase' : 'text-xs'} label-${f.fields.type} ${labels == true ? '' : 'hideme'}`" />
        </template>
        <!-- group -->
        <div class="h-full" v-if="f.fields.type == 'space'" />
        <div class="flex w-full" v-else-if="f.fields.type == 'group'">
          <div v-for="(s, i) in f.fields.fields" :key="i" :class="`${groupFieldWidth(s.fields.width)} ${i > 0 ? 'pl-2' : ''} ${initialized[s.fields.name] == true ? 'initialized' : 'untouched'} ${errors && errors.indexOf(s.fields.name) > -1 ? 'error form-error' : ''}`">
            <label :for="s.fields.name" v-html="s.fields.label" class="block w-full hideme" />
            <input
              :id="s.fields.name"
              :name="s.fields.name"
              :type="s.fields.type"
              :placeholder="s.fields.label.toUpperCase()"
              :min="s.fields.type === 'number' && s.fields.min ? s.fields.min : null"
              :max="s.fields.type === 'number' && s.fields.max ? s.fields.max : null"
              v-model="values[s.fields.name]"
              v-on:focus="onFocus(s)"
              v-on:blur="onBlur(s)"
              v-on:change="onChange"
              v-on:keyup="onKeyup"
              class="w-full pb-2 border-b-2 border-black" />
          </div>
        </div>
        <!-- select -->
        <div class="w-full" v-else-if="f.fields.type == 'select'" >
          <mobile-select :field="f.fields" :model="values" :handleUpdate="selectUpdate" />
          <!-- <form-select v-else :field="f.fields" :model="values" :handleUpdate="selectUpdate" /> -->
        </div>
        <!-- TODO: add checkbox, radio, textarea, etc -->
        <div v-else-if="f.fields.type == 'date'" class="relative w-full">
          <input type="hidden" :name="f.fields.name" :disabled="disabled" v-model="values[f.fields.name]" />
          <div
            class="w-full pb-2 border-b-2 border-black date-field cursor"
            v-on:click="openCalendar(f.fields.name)"
            v-html="formatDate(f)" />
          <div class="absolute z-20 w-full text-white bg-purple date-calendar" v-if="calendar == f.fields.name && !disabled">
            <date-pick
              :name="f.fields.name"
              color="purple"
              :minimum="f.fields.minimum"
              :disableDayOfWeek="f.fields.disableDayOfWeek"
              :selected="values[f.fields.name]"
              :handleUpdate="onCalendarChange"
              :handleClose="openCalendar" />
          </div>
        </div>
        <counter v-else-if="f.fields.type == 'counter'" :field="f" :model="values[f.fields.name]" color="purple" :handleChange="onCounterChange" />
        <!-- basics like text, email, password, maybe phone -->
        <input
          v-else
          :id="f.fields.name"
          :name="f.fields.name"
          :type="f.fields.type"
          :disabled="disabled && disabled.indexOf(f.fields.name) > -1"
          :placeholder="f.fields.label.toUpperCase()"
          :min="f.fields.type === 'number' && f.fields.min ? f.fields.min : null"
          :max="f.fields.type === 'number' && f.fields.max ? f.fields.max : null"
          v-model="values[f.fields.name]"
          v-on:focus="onFocus(f)"
          v-on:blur="onBlur(f)"
          v-on:keyup="onKeyup"
          v-on:change="onChange"
          class="w-full pb-2 border-b-2 border-black" />
      </div>
    </div>
    <div v-if="submitText" :class="`${inline ? 'flex-none' : 'w-full'} pt-4 px-2 text-center flex ${submitBtnAlignment}`">
      <div v-if="cancelText" :class="cancelBtnAlignment">
        <button v-on:click.prevent="onCancel" :class="cancelColor ? cancelColor : 'purple'" v-html="cancelText" />
      </div>
      <div>
        <button v-html="submitText" :class="submitColor ? submitColor : 'pink'" />
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment'
import { detect } from 'detect-browser'

import MobileSelect from '../forms/MobileSelect'
import FormSelect from './Select'
import DatePick from './DatePick'
import Counter from './Counter'

export default {
  components: {
    FormSelect,
    MobileSelect,
    DatePick,
    Counter
  },
  props: [
    'id',
    'handleSubmit',
    'handleBlur',
    'handleKeyup',
    'handleChange',
    'handleCancel',
    'fields',
    'values',
    'errors',
    'disabled',
    'submitText',
    'submitColor',
    'submitAlign',
    'cancelText',
    'cancelColor',
    'inline',
    'labels'
  ],
  data() {
    return {
      // values: {},
      calendar: null,
      initialized: {},
      focus: null
    }
  },
  computed: {
    mobile() {
      const browser = detect()
      if(browser) {
        switch(browser.os) {
          case 'iOS':
          case 'Android OS':
            return true
            break
          default:
            return false
        }
      } else {
        return false
      }
    },
    submitBtnAlignment() {
      switch(this.submitAlign) {
        case 'left':
          return 'justify-start'
          break
        case 'right':
          return 'justify-end'
          break
        default:
          return 'justify-center'
      }
    },
    cancelBtnAlignment() {
      switch(this.submitAlign) {
        case 'left':
          return 'order-last ml-2'
          break
        default:
          return 'mr-2'
      }
    },
  },
  beforeMount() {
    this.fields.map(f => {
      if(f.fields.type == 'group') {
        this.initialized[f.fields.name] = true
        f.fields.fields.map(s => {
          this.initialized[s.fields.name] = false
        })
      } else {
        this.initialized[f.fields.name] = false
      }
    })
  },
  methods: {
    groupFieldWidth(f) {
      let width = ''
      if(f) {
        switch(f) {
          case '1/5':
            width = 'w-16'
            break
          case '4/5':
            width = 'flex-grow'
            break
        }
      }
      return width
    },
    fieldWidth(f) {
      let width = ''
      if(f.fields.width) {
        switch(f.fields.width) {
          case '1/2':
            width = 'lg:w-1/2'
            break
          case '1/3':
            width = 'lg:w-1/3'
            break
          case 'auto':
            width = 'lg:w-auto'
            break
        }
      }
      return width
    },
    formatDate(f) {
      if(!this.values[f.fields.name]) {
        return f.fields.label
      } else if(f.fields.format) {
        return moment(this.values[f.fields.name], f.fields.format).format(f.fields.format)
      } else {
        return moment(this.values[f.fields.name], 'MM/DD/YYYY').format('MM/DD/YYYY')
      }
    },
    openCalendar(n) {
      this.calendar = n
    },
    onCalendarChange(d) {
      let format = null
      this.fields.map(f => {
        if(f.fields.name == d.name) {
          format = f.fields.format
        }
      })
      this.values[d.name] = moment(d.date, format).format(format)
      this.openCalendar(null)
      this.onChange()
    },
    onFocus(f) {
      console.log('onFocus')
      if(this.initialized[f.fields.name] == false) {
        this.initialized[f.fields.name] = true
      }
      this.focus = f.fields.name
    },
    onBlur(f) {
      console.log('onBlur')
      if(this.handleBlur) {
        this.handleBlur(this.values)
      }
      this.focus = null
    },
    onChange(e) {
      if(this.handleChange) {
        this.handleChange(this.values)
      }
    },
    selectUpdate(name, value) {
      this.values[name] = value
      console.log(name, value, this.values)
    },
    onCounterChange(f, c) {
      if(
        (c > 0 && (f.fields.max == null || this.values[f.fields.name] < f.fields.max)) ||
        (c < 0 && (f.fields.min == null || this.values[f.fields.name] > f.fields.min))) {
        this.values[f.fields.name] += c
        this.onChange()
      }
    },
    onKeyup(e) {
      if(this.handleKeyup) {
        this.handleKeyup(this.values)
      }
    },
    onCancel() {
      if(this.handleCancel) {
        this.handleCancel()
      }
    },
    fullWidthCounterClasses(f) {
      if(f.fields.fullWidthCounter && f.fields.type == 'counter') {
        const active = this.values[f.fields.name] >= f.fields.min && this.values[f.fields.name] > 0 ? 'active' : ''
        return `full-width-counter pt-3 pb-3 px-4 group ${active}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables.scss';

label.hideme:not(.label-counter) {
  font-size: 0;
  float: left;
}
.date-field {
  background: url('../../assets/img/icons/calendar.svg') no-repeat right 2px center;
  background-size: 25px;
}
input[type=password] {
  background: url('../../assets/img/icons/padlock.svg') no-repeat right 2px center;
}
.error {
  input[type=password] {
    background-image: url('../../assets/img/icons/padlock-red.svg');
  }
}
.untouched, .focused {
  input, .date-field {
    color: black !important;
    border-color: black !important;

    &::placeholder {
      color: black !important;
    }
  }
}
.full-width-counter {
  @apply w-full border-4 border-purple items-center justify-between gap-4;
  @apply transition-all duration-200 ease-in-out z-0;

  &::after {
    @apply w-full h-full block absolute opacity-0;
    @apply transition-all duration-200 ease-in-out;
    content: '';
    z-index: -1;
    background: $purple;
    background-image: linear-gradient(to right, $purple, $magenta);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-content {
    label,
    p {
      @apply transition-all duration-200 ease-in-out;
    }
  }

  ::v-deep .counter-button.disabled {
    background-color: #D1BAFF;
    border-color: #D1BAFF;
    opacity: .85;
  }

  ::v-deep .counter-content {
    @apply transition-all duration-200 ease-in-out;
  }

  &:hover,
  &.active {
    @apply border-transparent;

    &::after {
      @apply opacity-100;
    }

    .full-width-counter-content {
      label,
      p{
        @apply text-white;
      }
    }

    ::v-deep .counter-content {
      @apply text-white;
    }
  }
}
</style>
