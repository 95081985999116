<template>
  <div class="triangle-bg py-40 flex items-center justify-center">
    <div class="relative bg-white border-4 border-blue rounded-lg px-8 pb-12 w-full max-w-md">
      <div class="relative form-title transform -translate-y-1/2 flex z-20 -ml-2">
        <h2 class="w-auto bg-blue text-white text-xl uppercase font-bold px-2 py-1 rounded">Sign in</h2>
      </div>
      <form-main
        :id="formId"
        :handleBlur="getLoginDetails"
        :handleChange="getLoginDetails"
        :handleSubmit="login"
        :fields="fields"
        :values="defaults"
        :errors="errors"
        submitText="Sign In"
        submitColor="purple"
        submitAlign="right" />
      <div class="flex flex-wrap justify-center">
        <p class="w-full text-xs text-center mb-6"><nuxt-link class="underline" to="/password/forgot/">Forgot your password?</nuxt-link></p>
        <p class="w-full font-bold uppercase text-center mb-3">Don’t have an account?</p>
        <div>
          <a :href="signupURL" class="button pink">Create an Account</a>
        </div>
      </div>
      <div v-if="loggingIn" class="absolute inset-0 flex items-center rounded-lg justify-center bg-white-95">
        <loader color="purple" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '~/components/common/Loader'
import FormMain from '../../components/forms/FormMain'

export default {
  components: {
    Loader,
    FormMain
  },
  data: function() {
    return {
      formId: 'loginForm',
      loggingIn: false,
      fields: [
        {
          fields: {
            label: 'Email',
            name: 'email',
            type: 'email'
          }
        },
        {
          fields: {
            label: 'Password',
            name: 'password',
            type: 'password',
            errorMsg: 'Please check your login credentials and try again.'
          }
        }
      ],
      defaults: {
        email: process.env.OKTA_USERNAME,
        password: process.env.OKTA_PASSWORD
      },
      errors: [],
      loginInfo: {
        email: null,
        password: null
      }
    }
  },
  head() {
    return {
      title: 'Login'
    }
  },
  mounted: async function() {
    if (this.$nuxt.context.from && this.$nuxt.context.from.fullPath.includes('league')) {
      localStorage.setItem('me_redirect', '/book/leagues')
    } else {
      localStorage.removeItem('me_redirect')
    }
    console.log('redirect cookie: ', localStorage.getItem('me_redirect'))
    let session = await this.$okta.checkSession()
    if(session) {
      this.$router.push('/account')
    }
  },
  computed: {
    signupURL() {
      return process.env.OKTA_SIGNUP
    },
    loggedIn() {
      return this.$store.state.loggedIn
    }
  },
  methods: {
    getLoginDetails(form) {
      this.loginInfo = form
    },
    async login() {
      this.errors = []
      this.loggingIn = true
      let login = await this.$okta.logIn(this.defaults.email, this.defaults.password)
      if(!login) {
        this.loggingIn = false
        this.errors = ['email', 'password']
      }
    }
  }
}
</script>
